import React, { useState } from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { HomePortfolioCard } from "./homePortfolioCard";

export const HomePortfolio = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  let currentTimeout = null;
  let targetIndex = null;
  const founders = data.founders;

  const onFounderOver = (index) => {
    if (window.innerWidth < 768) {
      return;
    }

    if (targetIndex !== index) {
      clearTimeout(currentTimeout);
      currentTimeout = null;
    }

    if (currentTimeout === null) {
      targetIndex = index;
      currentTimeout = setTimeout(() => {
        setActiveIndex(index);
        targetIndex = null;
      }, 200);
    }
  };

  const onFounderClick = (index) => {
    currentTimeout = null;
    targetIndex = null;
    setActiveIndex(index);
  };

  const onFounderLeave = () => {
    currentTimeout = null;
    targetIndex = null;
    clearTimeout(currentTimeout);
  };

  const closeFounder = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveIndex(null);
  };

  return (
    <section className="bg-yellow md:bg-white section overflow-hidden">
      <div className="text-dark relative" data-aos="fade-up">
        <div className="container">
          <h2 className="small-title bordered mb-5 md:mb-14">{data.title}</h2>
          <div className="hidden lg:grid grid-cols-12">
            <div
              className={cx(
                "col-span-4 w-full mt-12 lg:mt-0 mb-10 lg:mb-0",
                activeIndex !== null && "hidden lg:block"
              )}
              onMouseLeave={() => onFounderLeave()}
            >
              {founders.map((founder, index) => (
                <div
                  key={founder._id}
                  className="mr-6 whitespace-nowrap lg:whitespace-pre-wrap"
                >
                  <h3
                    onClick={() => onFounderClick(index)}
                    onMouseEnter={() => onFounderOver(index)}
                    className={cx(
                      "text-2xl md:text-4xl font-semibold cursor-pointer inline-block leading-none mb-1",
                      {
                        underline: activeIndex === index,
                      }
                    )}
                  >
                    {founder.name}
                  </h3>
                </div>
              ))}

              <div className="mt-16">
                <Link className="button--outline min-width" to="/portfolio">
                  See Full Portfolio
                </Link>
              </div>
            </div>
            <div className="relative col-span-8">
              {founders.map((founder, index) => (
                <HomePortfolioCard
                  className={activeIndex === index ? "lg:block" : "lg:hidden"}
                  onCloseClick={(event) => closeFounder(event)}
                  founder={founder}
                  key={founder.id}
                />
              ))}
            </div>
          </div>
          <div className="lg:hidden">
            <div className="mb-5 md:mb-20 flex flex-row gap-8 w-full overflow-y-auto -mx-6 px-6 flush-component pb-5">
              {founders.map((founder) => (
                <HomePortfolioCard
                  className="mr-0 last:mr-0 flex-auto"
                  founder={founder}
                  key={founder.id}
                />
              ))}
            </div>

            <Link className="button--outline min-width" to="/portfolio">
              See Full Portfolio
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
