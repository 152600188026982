import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";
import cx from "classnames";
import * as styles from "./secondaryHero.module.scss";

export const SecondaryHero = ({ data }) => (
  <section className="h-screen w-full relative">
    <div
      className="parallax h-screen w-full pt-mnav md:pt-dnav overflow-hidden text-white"
      style={{ backgroundColor: data.bgColor?.hex, color: data.textColor?.hex }}
    >
      {data.image?.asset && (
        <SanityImage
          className="top-0 left-0 h-full w-full object-cover absolute z-0"
          alt={data.title}
          {...data.image}
          width={3840}
          height={2160}
        />
      )}

      <div
        className={cx(
          data.image?.asset,
          "container h-full w-full relative z-10"
        )}
      >
        <div className="font-semibold leading-none flex flex-col place-content-center py-10 h-full w-full">
          <h1
            className={cx(styles.title, "text-center w-full")}
            data-aos="fade"
            data-aos-delay="200"
          >
            {data.title}
          </h1>
          <h1
            className={cx(styles.subtitle, "text-center w-full")}
            data-aos="fade"
            data-aos-delay="200"
          >
            {data.subtitle}
          </h1>
        </div>
      </div>
    </div>
  </section>
);
