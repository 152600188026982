import React from "react";
import Arrow from "../icons/arrow";
import cx from "classnames";
import * as styles from "./peopleJobs.module.scss";

export const PeopleJobs = ({ data }) => (
  <section className="section bg-blue text-dark">
    <div className="container">
      <h2 className="small-title bordered md:mb-16 mb-8">{data.title}</h2>
      <h3 className="text-5xl font-semibold max-w-4xl my-10">{data.copy}</h3>
      <div>
        {data.jobs.map((job) => (
          <a
            key={job._key}
            className={cx(
              "py-10 px-4 flex flex-row items-center w-full border-black border-b",
              styles.job
            )}
            href={job.link}
            target="_blank"
            rel="noreferrer"
          >
            <h4 className="text-xl mr-10">{job.title}</h4>
            <p className={cx("font-serif italic", styles.jobCopy)}>
              {job.copy}
            </p>
            <div className={cx("flex-1 text-right", styles.jobCopy)}>
              <Arrow className="inline-block" />
            </div>
          </a>
        ))}
      </div>
    </div>
  </section>
);
