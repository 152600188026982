import { graphql, useStaticQuery } from "gatsby";
import processNodes from "./processNodes";

const FoundersQuery = () => {
  const { allSanityFounder } = useStaticQuery(graphql`
    query FoundersQuery {
      allSanityFounder(sort: { fields: name, order: ASC }) {
        nodes {
          id
          name
          about
          year
          exited
          link
          _rawWhatWeDid(resolveReferences: { maxDepth: 10 })
          _rawTeam(resolveReferences: { maxDepth: 10 })
          _rawSocial(resolveReferences: { maxDepth: 10 })
          _rawSlug(resolveReferences: { maxDepth: 10 })
          logo {
            asset {
              _id
            }
          }
          image {
            asset {
              _id
            }
          }
          industries {
            industry {
              id
            }
          }
        }
      }
    }
  `);
  return processNodes(allSanityFounder);
};
export default FoundersQuery;
