import React from "react";
import { ImageHero } from "./imageHero";
import { HomePortfolio } from "./homePortfolio";
import { HomePeople } from "./homePeople";
import { HomeApproach } from "./homeApproach";
import { HomeBlogPosts } from "./homeBlogPosts";
import { CopyHero } from "./copyHero";
import { PeopleTeam } from "./peopleTeam";
import { PeopleValues } from "./peopleValues";
import { PeopleJobs } from "./peopleJobs";
import { PortfolioCompanies } from "./portfolioCompanies";
import { Copy } from "./copy";
import { Cta } from "./cta";
import { ApproachSection } from "./approachSection";
import { LegalCopy } from "./legalCopy";
import { CtaHero } from "./ctaHero";
import { MediaSection } from "./mediaSection";
import { Quote } from "./quote";
import { SecondaryHero } from "./secondaryHero";
import { Headline } from "./headline";
import { Logos } from "./logos";
import { CardSection } from "./cardSection";
import { Cards } from "./cards";
import { QuoteCard } from "./quoteCard";
import { Video } from "./video";

export const Sections = ({ reactSection, location }) => {
  switch (reactSection._type) {
    case "imageHero":
      return <ImageHero data={reactSection} />;
    case "homePortfolio":
      return <HomePortfolio data={reactSection} />;
    case "homePeople":
      return <HomePeople data={reactSection} />;
    case "homeApproach":
      return <HomeApproach data={reactSection} />;
    case "homeBlogPosts":
      return <HomeBlogPosts data={reactSection} />;
    case "copyHero":
      return <CopyHero data={reactSection} />;
    case "peopleTeam":
      return <PeopleTeam data={reactSection} location={location} />;
    case "peopleValues":
      return <PeopleValues data={reactSection} />;
    case "peopleJobs":
      return <PeopleJobs data={reactSection} />;
    case "portfolioCompanies":
      return <PortfolioCompanies data={reactSection} location={location} />;
    case "copy":
      return <Copy data={reactSection} />;
    case "cta":
      return <Cta data={reactSection} />;
    case "approachSection":
      return <ApproachSection data={reactSection} />;
    case "legalCopy":
      return <LegalCopy data={reactSection} />;
    case "ctaHero":
      return <CtaHero data={reactSection} />;
    case "media":
      return <MediaSection data={reactSection} />;
    case "quote":
      return <Quote data={reactSection} />;
    case "secondaryHero":
      return <SecondaryHero data={reactSection} />;
    case "headline":
      return <Headline data={reactSection} />;
    case "logos":
      return <Logos data={reactSection} />;
    case "card":
      return <CardSection data={reactSection} />;
    case "cards":
      return <Cards data={reactSection} />;
    case "quoteCard":
      return <QuoteCard data={reactSection} />;
    case "video":
      return <Video data={reactSection} />;
    default:
      return (
        <section className="text-center my-5">
          <b>Section &quot;{reactSection._type}&quot; not implemented.</b>
        </section>
      );
  }
};
