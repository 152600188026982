import React from "react";
import PortableText from "../components/portableText";

export const Copy = ({ data }) => (
  <section className="bg-white py-16 md:py-28">
    <div className="text-dark container" data-aos="fade-up">
      <PortableText
        className="font-serif max-w-2xl mx-auto text-sm md:text-lg2 leading-normal"
        blocks={data.copy}
      />
    </div>
  </section>
);
