import React from "react";
import { FounderCard } from "../components/founderCard";
import { PageLink } from "../components/link";
import { PostCard } from "../components/postCard";

export const ApproachSection = ({ data }) => (
  <section className="bg-white section">
    <div className="text-dark" data-aos="fade-up">
      <div className="container">
        <h2 className="small-title bordered md:mb-16 mb-8">{data.title}</h2>

        <div className="mt-10 md:grid grid-cols-12 gap-7">
          <h3 className="col-span-7 font-semibold text-2xl md:text-4xl2 mb-8 md:mb-0">
            {data.headline}
          </h3>
          <div className="col-span-5">
            <p className="md:max-w-sm font-serif md:text-sm text-xs text-left md:ml-auto leading-snug">
              {data.copy}
            </p>
          </div>
        </div>

        {data.article && (
          <PostCard
            className="mt-4 md:mt-20 md:mr-10 bg-ghost"
            post={data.article}
          />
        )}
        {data.founder && (
          <FounderCard
            className="mt-4 md:mt-28 md:max-w-5xl"
            founder={data.founder}
          />
        )}

        {data.ctaLink && data.ctaLink[0] && (
          <div className="mt-10">
            <PageLink
              className="button--outline min-width"
              link={data.ctaLink[0].link}
              _type={data.ctaLink[0]._type}
            >
              {data.ctaLink[0].title}
            </PageLink>
          </div>
        )}
      </div>
    </div>
  </section>
);
