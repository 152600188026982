import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import cx from "classnames";
import Arrow from "../icons/arrow.js";
import * as styles from "./../components/founderCard.module.scss";

export const Quote = ({ data }) => (
  <section className="bg-white section">
    <div className="container">
      <a
        href={data.link}
        className={cx(
          "block lg:grid grid-cols-12 md:p-14 md:pb-12 p-6 bg-yellow gap-14 md:max-w-5xl",
          styles.wrapper
        )}
      >
        {data.image ? (
          <div className="col-span-4">
            <SanityImage
              className="max-w-full rounded-full overflow-hidden mb-7 md:mb-0 w-10/12 lg:w-full"
              alt={data.copy}
              {...data.image}
              width={500}
              height={500}
            />
          </div>
        ) : (
          ""
        )}

        <div className="col-span-8">
          <p className="font-serif mb-9 text-lg md:text-lg3">{data.copy}</p>
          <div className="flex flex-row justify-between items-center">
            {data.footer && (
              <p className={cx("font-sans text-2xs", styles.footer)}>
                {data.footer}
              </p>
            )}
            {data.link && (
              <div>
                <Arrow className={cx(styles.arrow)} />
              </div>
            )}
          </div>
        </div>
      </a>
    </div>
  </section>
);
