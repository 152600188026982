import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { HomePerson } from "../components/homePerson";
import * as styles from "./homeApproach.module.scss";
import cx from "classnames";

export const HomePeople = ({ data }) => {
  const slickSettings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    adaptiveHeight: true,
  };

  const [people, setPeople] = useState([]);

  useEffect(() => {
    setPeople(data.people.sort(() => Math.random() - 0.5));
  }, [data.people]);

  return (
    <section className="bg-blue md:bg-white section overflow-x-hidden">
      <div className="text-dark" data-aos="fade-up">
        <div className="container">
          <h2 className="small-title bordered mb-5 md:mb-16">{data.title}</h2>

          <div className="max-w-5xl mb-16">
            <h3 className="mb-11 lg:mt-16 lg:mb-16 text-2xl lg:text-4xl font-semibold lg:mr-16">
              {data.copy}
            </h3>
            <Slider
              className="hidden lg:block show-overflow grayscale-next"
              {...slickSettings}
            >
              {data.people.map((person) => (
                <HomePerson
                  className="pr-5 h-full"
                  key={person._id}
                  person={person}
                />
              ))}
            </Slider>
            <div className="lg:hidden mb-10 md:mb-20 flex flex-row gap-8 md:grid grid-cols-2 xl:grid-cols-4 w-full overflow-y-auto -mx-6 px-6 lg:mx-0 lg:px-0 flush-component pb-5">
              {people.map((person) => (
                <HomePerson
                  className={cx(
                    styles.approach,
                    "last:mr-0 flex-auto bg-white lg:bg-pink xl:w-auto"
                  )}
                  key={person._id}
                  person={person}
                />
              ))}
            </div>
          </div>

          <Link className="button--outline min-width" to="/people">
            Meet The Team
          </Link>
        </div>
      </div>
    </section>
  );
};
