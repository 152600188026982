import React, { createRef, useEffect, useRef, useState } from "react";
import { PersonCard } from "../components/personCard";
import PeopleQuery from "../static-queries/peopleQuery";
import queryString from "query-string";

export const PeopleTeam = (props) => {
  const [openedPerson] = useState(
    queryString.parse(props.location.search)["slug"]
  );
  const people = PeopleQuery();
  const peopleIndex = people.findIndex(
    (person) => openedPerson === person.slug.current
  );
  const peopleRefs = useRef([]);

  if (peopleRefs.current.length !== people.length) {
    peopleRefs.current = Array(people.length)
      .fill()
      .map((_, i) => peopleRefs.current[i] || createRef());
  }

  const openedPersonRef = peopleRefs.current[peopleIndex];
  useEffect(() => {
    if (peopleRefs.current[peopleIndex])
      peopleRefs.current[peopleIndex].current.openModal();
  }, [openedPersonRef, peopleIndex]);

  const onPersonOpened = (person) => {
    const currentSlug = queryString.parse(window.location.search)["slug"];
    if (currentSlug !== person.slug.current) {
      const url = `${props.location.origin}/people?slug=${person.slug.current}`;
      window.history.pushState({}, null, url);
    }
  };

  const onPersonClosed = () => {
    const currentSlug = queryString.parse(window.location.search)["slug"];
    if (currentSlug) {
      const url = `${props.location.origin}/people`;
      window.history.pushState({}, null, url);
    }
  };

  return (
    <section className="bg-white section">
      <div className="text-dark">
        <div className="container">
          <h2 className="small-title bordered md:mb-16 mb-8">
            {props.data.title}
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
            {people.map((person, index) => (
              <PersonCard
                ref={peopleRefs.current[index]}
                key={person.id}
                person={person}
                onOpen={() => onPersonOpened(person)}
                onClose={() => onPersonClosed()}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
