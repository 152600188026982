import { graphql, useStaticQuery } from "gatsby";
import processNodes from "./processNodes";

const PeopleQuery = () => {
  const { allSanityPerson } = useStaticQuery(graphql`
    query PeopleQuery {
      allSanityPerson(sort: { fields: indexRank, order: ASC }) {
        nodes {
          name
          quote
          position
          id
          _id
          _rawSlug
          _rawDescription
          _rawFooter
          _rawFooterLinks
          _rawImage(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);
  return processNodes(allSanityPerson);
};
export default PeopleQuery;
