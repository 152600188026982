import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import cx from "classnames";
import { Link } from "gatsby";
import Arrow from "../icons/arrow";
import Close from "../icons/close";
import * as styles from "./homePortfolioCard.module.scss";

const HomePortfolioCardContent = ({ founder, onCloseClick, hasLink }) => (
  <div
    className={cx(
      "p-9 xl:p-16 md:grid grid-cols-2 gap-5 lg:gap-10 w-full h-full relative"
    )}
  >
    {onCloseClick && (
      <div
        className="absolute right-3 top-3 p-5 z-10"
        onClick={(event) => onCloseClick(event)}
      >
        <Close
          className="lg:hidden fill-current text-dark  stroke-current "
          width="13"
          height="13"
        />
      </div>
    )}

    <div className="relative">
      {founder.image && (
        <SanityImage
          className="rounded-full overflow-hidden md:hidden w-11/12 mb-5 mx-auto lg:mx-0"
          alt={founder.name}
          {...founder.image}
          width={450}
          height={450}
        />
      )}
      {founder.logo && (
        <SanityImage
          className="text-center lg:text-left filter grayscale mix-blend-multiply hide-lqip lg:h-20"
          alt={founder.name}
          {...founder.logo}
          height={80}
        />
      )}
      <p
        className="text-4xs lg:text-sm font-semibold"
        style={{ maxWidth: "280px" }}
      >
        {founder.subtitle}
      </p>
      <p className="text-lg2 md:hidden lg:block leading-tight lg:text-2xl2 my-5 lg:my-14 font-serif">
        {founder.about}
      </p>
    </div>
    <div className="relative h-full md:flex items-start hidden">
      {founder.image && (
        <SanityImage
          className="rounded-full overflow-hidden"
          alt={founder.name}
          {...founder.image}
          width={450}
          height={450}
        />
      )}
    </div>

    <p className="col-span-2 text-lg2 hidden md:block lg:hidden leading-tight lg:text-2xl2 lg:my-14 font-serif pb-5 lg:pb-0">
      {founder.about}
    </p>

    {founder.year && (
      <div className="text-4xs lg:text-sm col-span-2 absolute bottom-2 left-0 lg:static w-full px-9 lg:px-0">
        <div>
          First invested: {founder.year}
          {hasLink ? (
            <Link
              className="p-4 float-right -mt-3.5 inline-block"
              to={`/portfolio?founder_slug=${founder.slug.current}`}
            >
              <Arrow />
            </Link>
          ) : (
            <span className="p-4 float-right -mt-3.5 inline-block">
              <Arrow />
            </span>
          )}
        </div>
      </div>
    )}
  </div>
);

export const HomePortfolioCard = ({ founder, className, onCloseClick }) => (
  <>
    <Link
      className={cx("hidden bg-white md:bg-yellow", className, styles.founder)}
      to={`/portfolio?founder_slug=${founder.slug.current}`}
    >
      <HomePortfolioCardContent
        founder={founder}
        onCloseClick={onCloseClick}
        hasLink={false}
      />
    </Link>
    <div
      className={cx(
        "lg:hidden bg-white md:bg-yellow",
        className,
        styles.founder
      )}
    >
      <HomePortfolioCardContent
        founder={founder}
        onCloseClick={onCloseClick}
        hasLink={true}
      />
    </div>
  </>
);
