import React from "react";
import { PageLink } from "../components/link.js";
import * as styles from "./../components/founderCard.module.scss";
import cx from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import Arrow from "../icons/arrow.js";

export const QuoteCard = ({ data }) => {
  const content = (
    <div
      className={cx(
        "block lg:grid grid-cols-12 md:p-14 md:pb-12 p-6 bg-ghost gap-7 max-w-5xl items-center",
        styles.wrapper
      )}
    >
      {data.logo && (
        <div className="col-span-3">
          <div className={styles.logo}>
            <div className="rounded-full overflow-hidden mb-7 lg:mb-0 lg:w-full bg-white aspect-w-1 aspect-h-1 object-contain">
              <div>
                <SanityImage
                  className="w-full h-full object-contain p-5"
                  alt="image"
                  {...data.logo}
                  width={250}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="col-span-9">
        {data.copy && (
          <p className="font-serif mb-9 text-sm lg:text-lg3">{data.copy}</p>
        )}
        <div className="flex flex-row justify-between items-center">
          {data.subtitle && (
            <p
              className={cx(
                "font-sans text-4xs lg:text-2xs",
                styles.quoteSubtitle
              )}
            >
              {data.subtitle}
            </p>
          )}
          {data.link && data.link[0] && (
            <div>
              <Arrow className={cx(styles.arrow)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <section className="bg-white md:py-14 py-6">
      <div className="text-dark container" data-aos="fade-up">
        {data.link && data.link[0] ? (
          <PageLink {...data.link[0]}>{content}</PageLink>
        ) : (
          content
        )}
      </div>
    </section>
  );
};
