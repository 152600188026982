import React from "react";
import { Card } from "../components/card.js";
import * as styles from "./homeApproach.module.scss";
import cx from "classnames";

export const Cards = ({ data }) => (
  <section className="bg-white md:py-16 py-10">
    <div className="text-dark container" data-aos="fade-up">
      <div
        className={cx(
          "flex flex-row md:grid gap-7 overflow-y-auto px-6 lg:mx-0 flush-component lg:px-0",
          `grid-cols-${data.cards.length}`
        )}
      >
        {data.cards.map((card) => (
          <div key={card._key} className={styles.approach}>
            <Card data={card} />
          </div>
        ))}
      </div>
    </div>
  </section>
);
