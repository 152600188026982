import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "./portableText.js";
import cx from "classnames";
import Arrow from "../icons/arrow.js";
import * as styles from "./founderCard.module.scss";
import { Link } from "gatsby";

export const FounderCard = ({ founder, className }) => (
  <Link
    to={`/portfolio?founder_slug=${founder.slug.current}`}
    className={cx(
      className,
      "block lg:grid grid-cols-12 md:p-14 md:pb-12 p-6 bg-yellow gap-14",
      styles.wrapper
    )}
  >
    {founder.quoteImage || founder.image ? (
      <div className="col-span-4">
        <SanityImage
          className="max-w-full rounded-full overflow-hidden mb-7 md:mb-0 w-10/12 lg:w-full"
          alt={founder.name}
          asset={
            founder.quoteImage ? founder.quoteImage.asset : founder.image.asset
          }
          crop={
            founder.quoteImage ? founder.quoteImage.crop : founder.image.crop
          }
          hotspot={
            founder.quoteImage
              ? founder.quoteImage.hotspot
              : founder.image.hotspot
          }
          width={500}
          height={500}
        />
      </div>
    ) : (
      ""
    )}

    <div className="col-span-8">
      <p className="font-serif mb-9 text-lg md:text-lg3">{founder.quote}</p>
      <div className="flex flex-row justify-between items-center">
        {founder.quoteSubtitle && (
          <PortableText
            className={cx("font-sans text-2xs", styles.quoteSubtitle)}
            blocks={founder.quoteSubtitle}
          />
        )}
        <div>
          <Arrow className={cx(styles.arrow)} />
        </div>
      </div>
    </div>
  </Link>
);
