import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import Popup from "reactjs-popup";
import PortableText from "./portableText.js";
import cx from "classnames";
import * as styles from "./personCard.module.scss";
import { PageLink } from "./link.js";
import Arrow from "../icons/arrow.js";
import sanityClient from "../lib/sanityClient";
import BlogIndexQuery from "../static-queries/blogIndexQuery.js";
import { Link } from "gatsby";

export const PersonCard = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [articles, setArticles] = useState(null);
  const { person, className } = props;

  const fetchArticles = async () => {
    const response = await sanityClient.fetch(`
    *[_type == 'post' && references("${person._id}")][0..1] | order(_createdAt desc)  {
      ...,
    }`);
    setArticles(response);
  };

  useEffect(() => {
    if (open && articles == null) {
      fetchArticles();
    }
  }, [open]);

  const openModal = () => {
    setOpen(true);

    if (props.onOpen) {
      props.onOpen();
    }
  };

  const closeModal = () => {
    setOpen(false);

    if (props.onClose) {
      props.onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    openModal() {
      setOpen(true);
    },
  }));

  return (
    <>
      <div
        data-aos="fade-up"
        onClick={() => setOpen(true)}
        className={cx(className, "cursor-pointer", styles.card)}
      >
        <SanityImage
          className={cx("max-w-full", styles.personImage)}
          alt={person.name}
          {...person.image}
          width={400}
          height={500}
        />
        <div className="text-xs sm:text-sm sm:p-5 p-2">
          <div className="font-semibold mb-0.5">{person.name}</div>
          <Arrow
            className={cx("hidden md:block float-right -mt-2", styles.arrow)}
          />
          <div className="pr-6">{person.position}</div>
        </div>
      </div>
      <Popup
        modal
        lockScroll={true}
        open={open}
        closeOnDocumentClick={true}
        closeOnEscape={true}
        onOpen={() => openModal()}
        onClose={() => closeModal()}
      >
        {() => (
          <div className="md:m-auto max-w-screen-xl m-2.5 h-full overscroll-auto">
            <div className="bg-white relative">
              <div className="md:p-0 p-8">
                <div className="md:grid grid-cols-12 gap-5">
                  <div className="xl:col-span-4 col-span-5 md:block flex flex-col-reverse">
                    <SanityImage
                      className="max-w-full"
                      alt={person.name}
                      {...person.image}
                      width={400}
                      height={500}
                    />
                    <div className="leading-none text-2xs md:text-sm md:ml-10 mt-1 md:mt-7 mb-6 md:mb-0 pb-4">
                      <div className="font-semibold">{person.name}</div>
                      <div>{person.position}</div>
                    </div>
                  </div>
                  <div className="xl:col-span-7 col-span-6 pt-8 md:pt-10">
                    <div className="max-w-lg ml-auto h-full flex items-end lg:pb-20">
                      {person.quote && (
                        <blockquote className="font-serif text-lg md:text-xl mb-5 md:mb-10">
                          {person.quote}
                        </blockquote>
                      )}
                      <PortableText
                        className="font-serif text-xs md:text-sm leading-5"
                        blocks={person.description}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 text-right">
                    <button
                      className={cx(
                        "close right-5 top-5 md:top-10 md:right-10 absolute",
                        styles.close
                      )}
                      onClick={() => setOpen(false)}
                    >
                      <svg
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="1.35355"
                          y1="0.646447"
                          x2="21.3536"
                          y2="20.6464"
                          stroke="black"
                        />
                        <line
                          x1="0.646447"
                          y1="20.6464"
                          x2="20.6464"
                          y2="0.646446"
                          stroke="black"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="border-black border-b md:my-7"></div>
              <div className="md:p-0 p-8 md:pb-8">
                <div className="md:grid grid-cols-12 md:px-12 gap-5">
                  <div className="mb-5 md:mb-0 col-span-3">
                    {person.footerLinks
                      ? person.footerLinks.map(({ link, title, _key }) => (
                          <div key={_key} className="leading-5">
                            <PageLink
                              className="link-effect font-serif text-xs md:text-sm leading-5"
                              link={link}
                              _type="externalLink"
                            >
                              {title}
                            </PageLink>
                          </div>
                        ))
                      : ""}
                  </div>
                  <div className="font-serif text-xs md:text-sm leading-5 col-span-9">
                    <div className="ml-auto" style={{ maxWidth: "574px" }}>
                      {person.footer && (
                        <PortableText
                          className="no-margin-after-p"
                          blocks={person.footer}
                        />
                      )}
                      {articles && articles.length > 0 && (
                        <div className="font-serif text-xs md:text-sm leading-5 -mb-6">
                          <strong className="font-sans align-top pr-1.5 block">
                            Articles:
                          </strong>
                          {articles.map((article) => (
                            <Link
                              to={`/${BlogIndexQuery().slug.current}/posts/${
                                article.slug.current
                              }`}
                              className="link-effect"
                              key={article._id}
                            >
                              {article.title}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
});
PersonCard.displayName = "PersonCard";
