import React from "react";
import cx from "classnames";
import * as styles from "./peopleValues.module.scss";

export const PeopleValues = ({ data }) => (
  <section className="bg-blue section">
    <div className="text-dark" data-aos="fade-up">
      <div className="container">
        <h2 className="small-title bordered md:mb-16 mb-8">{data.title}</h2>

        <h3 className="mb-16 lg:mt-16 lg:mb-20 text-2xl lg:text-4xl font-semibold lg:mr-16">
          {data.copy}
        </h3>

        <div className={cx("sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-5")}>
          {data.values.map((value) => (
            <div
              key={value._key}
              className={cx(
                "border-black border-2 py-14 px-6 flex items-center mb-4 md:mb-0",
                styles.box
              )}
            >
              <h3 className={cx("text-2xl mb-1", value.copy && styles.title)}>
                {value.title}
              </h3>
              <div
                className={cx(
                  "text-lg font-serif w-full px-6 flex flex-row items-center",
                  styles.copy
                )}
              >
                <p>{value.copy}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);
