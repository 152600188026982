import { Link } from "gatsby";
import React from "react";
import { PostCard } from "../components/postCard";
import BlogIndexQuery from "../static-queries/blogIndexQuery";

export const HomeBlogPosts = ({ data }) => (
  <section className="bg-ghost md:bg-white section">
    <div className="text-dark" data-aos="fade-up">
      <div className="container">
        <h2 className="small-title bordered">{data.title}</h2>

        <div className="max-w-6xl my-10 md:my-20">
          {data.posts.map((post) => (
            <PostCard
              key={post.id}
              className="mb-9 md:bg-ghost bg-white"
              post={post}
            />
          ))}
        </div>

        <Link
          className="button--outline min-width"
          to={`/${BlogIndexQuery().slug.current}`}
        >
          See More Stories
        </Link>
      </div>
    </div>
  </section>
);
