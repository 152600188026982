import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "./portableText.js";
import cx from "classnames";
import Arrow from "../icons/arrow.js";
import { Link } from "gatsby";
import BlogIndexQuery from "../static-queries/blogIndexQuery.js";
import * as styles from "./postCard.module.scss";
import YoutubeEmbed from "./youtubeEmbed.js";

export const PostCard = ({ post, className, layout = "landscape" }) => (
  <Link
    to={`/${BlogIndexQuery().slug.current}/posts/${post.slug.current}`}
    className={cx(
      className,
      layout === "landscape" && "block lg:grid grid-cols-12",
      layout === "portrait" && "flex flex-col"
    )}
  >
    {post.mainVideo?.youtubeUrl ? (
      <div className="col-span-4">
        <YoutubeEmbed
          className={cx(layout === "landscape" && "h-full")}
          youtubeVideo={post.mainVideo}
        />
      </div>
    ) : (
      post.mainImage && (
        <div className="col-span-4">
          <SanityImage
            className="w-full h-full object-cover"
            alt={post.title}
            {...post.mainImage}
            width={954}
            height={536}
          />
        </div>
      )
    )}
    <div
      className={cx(
        styles.copy,
        "col-span-8 flex-1 md:py-8 md:px-10 px-6 pt-6 pb-8"
      )}
    >
      <div className="max-w-lg">
        <div className="text-lg md:text-lg3 font-semibold leading-snug">
          {post.title}
        </div>
        {post.excerpt && (
          <PortableText
            className="font-serif md:my-5 my-8 leading-snug text-sm"
            blocks={post.excerpt}
          />
        )}
      </div>
      <div className="flex flex-row justify-between">
        <div>
          {post.authors?.length && (
            <div className="text-4xs uppercase">
              BY {post.authors.map((author) => author.name).join(", ")}
            </div>
          )}
        </div>
        <div>
          <Arrow className={cx(styles.arrow)} />
        </div>
      </div>
    </div>
  </Link>
);
