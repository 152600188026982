import { graphql, useStaticQuery } from "gatsby";
import processNodes from "./processNodes";

const IndustriesQuery = () => {
  const { allSanityIndustry } = useStaticQuery(graphql`
    query IndustriesQuery {
      allSanityIndustry {
        nodes {
          id
          name
        }
      }
    }
  `);
  return processNodes(allSanityIndustry);
};
export default IndustriesQuery;
