import React from "react";

const OpenClose = ({ className, height, width }) => (
  <svg
    className={className}
    style={{ height, width }}
    width="14"
    height="8"
    viewBox="0 0 14 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.11503"
      y1="0.557579"
      x2="7.83424"
      y2="7.27679"
      stroke="#222222"
    />
    <line
      x1="7.11591"
      y1="7.27643"
      x2="13.6352"
      y2="0.557218"
      stroke="#222222"
      strokeWidth="1.01658"
    />
  </svg>
);
export default OpenClose;
