import React from "react";

export const Headline = ({ data }) => (
  <section className="bg-white section">
    <div className="text-dark" data-aos="fade-up">
      <div className="container">
        <h2 className="small-title bordered mb-14">{data.title}</h2>
        <div className="mt-6 md:grid grid-cols-12 gap-5">
          <h3 className="col-span-7 font-semibold text-2xl md:text-4xl2 mb-8 md:mb-0">
            {data.headline}
          </h3>
          <div className="col-span-5">
            <p className="md:max-w-sm font-serif md:text-sm text-xs text-left md:ml-auto leading-snug">
              {data.copy}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);
