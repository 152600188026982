import React, { useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import Arrow from "../icons/arrow";
import cx from "classnames";
import { Link } from "gatsby";
import * as styles from "./postCard.module.scss";

export const HomePerson = ({ person, className, style }) => {
  const [clickState, setClickState] = useState({});

  const handleOnMouseDown = (e) => {
    setClickState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY,
    });
    e.preventDefault();
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (
      clickState.clientXonMouseDown !== e.clientX ||
      clickState.clientYonMouseDown !== e.clientY
    ) {
      // prevent link click if the element was dragged
      e.preventDefault();
    }
  };

  return (
    <Link
      to={`/people?slug=${person.slug.current}`}
      className={cx("inline-block", className, styles.copy)}
      style={style}
      draggable="false"
      onMouseDown={(e) => handleOnMouseDown(e)}
      onClick={(e) => handleOnClick(e)}
    >
      <div className="lg:grid grid-cols-12 gap-8 md:bg-blue bg-white p-6 lg:p-14">
        <div className="col-span-3 mb-7 lg:mb-0">
          <SanityImage
            alt={person.name}
            {...person.image}
            width={260}
            height={340}
          />
        </div>
        <div className="col-span-9 flex flex-col lg:mb-0">
          <div className="lg:mb-5 text-base md:text-xl lg:text-2xl2 font-serif mb-10 flex-1">
            {person.excerpt}
          </div>
          <div className="text-2xs md:text-sm flex flex-row items-center">
            <div className="flex-1">
              <b className="block">{person.name}</b>
              {person.position}
            </div>
            <div className="text-right">
              <Arrow className={cx(styles.arrow)} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
