import React from "react";
import * as styles from "./imageHero.module.scss";
import cx from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";

export const ImageHero = ({ data }) => (
  <section className="h-screen w-full relative">
    <div className="parallax h-screen w-full pt-mnav md:pt-dnav text-white overflow-hidden">
      {data.file?.asset.mimeType.startsWith("video/") ? (
        <video
          className="top-0 left-0 h-full w-full object-cover bg-black absolute z-0"
          onLoadedMetadata={(event) => (event.target.muted = true)}
          autoPlay={true}
          playsInline={true}
          muted={true}
          loop={true}
          preload="none"
        >
          <source src={data.file.asset.url} type={data.file.asset.mimeType} />
        </video>
      ) : (
        data.image?.asset && (
          <SanityImage
            className="top-0 left-0 h-full w-full object-cover bg-black absolute z-0"
            alt={data.title1}
            {...data.image}
            width={3840}
            height={2160}
          />
        )
      )}

      <div className="container h-full w-full relative z-10">
        <div className="font-semibold leading-none flex flex-col py-10 h-full w-full">
          <h1
            className={cx(
              styles.title,
              data.width === "50_50" ? "col-span-6" : "col-span-8",
              "text-center w-full"
            )}
            data-aos="fade"
            data-aos-delay="200"
          >
            {data.title1}
          </h1>
          <div
            className={cx(
              data.width === "50_50" ? "col-span-6" : "col-span-4",
              "hidden lg:block"
            )}
          ></div>
          <div
            className="flex-1 w-1 mx-auto my-5 border-r border-white"
            data-aos="fade"
            data-aos-delay="500"
          ></div>
          <div
            className={cx(
              styles.title,
              data.width === "50_50" ? "col-span-6" : "col-span-8",
              "flex flex-row items-end mb-safari sm:mb-0",
              "text-center"
            )}
            data-aos="fade"
            data-aos-delay="800"
            data-aos-anchor-placement="top-bottom"
          >
            <h2
              className={cx(
                styles.title,
                data.width === "50_50" ? "col-span-6" : "col-span-8",
                "text-center w-full"
              )}
            >
              {data.title2}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
);
