import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";

export const MediaSection = ({ data }) => (
  <section className="section text-dark">
    <div className="container">
      <h2 className="small-title bordered md:mb-10 mb-7">
        {data.sectionTitle}
      </h2>

      <div className="md:grid grid-cols-12 gap-7">
        <h3 className="col-span-7 font-semibold text-2xl md:text-5xl mb-16">
          {data.title}
        </h3>
      </div>

      <div className="md:grid grid-cols-2 gap-5">
        {data.mediaAssets.map((mediaAsset) => (
          <div key={mediaAsset._key} className="mb-5">
            <SanityImage
              className="w-full"
              alt={mediaAsset.title}
              {...mediaAsset.image}
              width={710}
              height={401}
            />
            <div className="bg-ghost p-6 pb-14">
              <h4 className="text-base md:text-xl mb-3">{mediaAsset.title}</h4>
              <p className="mb-8 font-serif">{mediaAsset.copy}</p>
              <a
                href={mediaAsset.file}
                target="_blank"
                rel="noreferrer"
                download
                className="button--outline"
              >
                Download {mediaAsset.title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);
