import React from "react";
import cx from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import { PageLink } from "../components/link";

export const Logos = ({ data }) => {
  const logoItem = (logo) => (
    <div className="my-7 md:my-0 ">
      <SanityImage
        {...logo.logo}
        className={cx(data.size === "large" ? "w-48 mx-auto" : "w-36")}
        alt={logo.title ?? "Logo"}
      />
      {logo.title && <h4 className="mt-3">{logo.title}</h4>}
    </div>
  );

  return (
    <section className="bg-white section">
      <div data-aos="fade-up">
        <div className="container">
          <div
            className={cx(
              "grid grid-cols-2 md:flex flex-row justify-start items-end"
            )}
          >
            {data.logos.map((logo) => (
              <div
                key={logo._key}
                className={cx(
                  data.size === "large"
                    ? "col-span-2"
                    : "col-span-1 text-center"
                )}
              >
                <div
                  className={cx(
                    data.size === "large"
                      ? "my-2 mx-auto"
                      : "my-2.5 inline-block mx-4",
                    "text-center px-8"
                  )}
                  key={logo._key}
                >
                  {logo.link && logo.link[0] ? (
                    <PageLink {...logo.link[0]}>{logoItem(logo)}</PageLink>
                  ) : (
                    logoItem(logo)
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
