import React from "react";
import cx from "classnames";

export const Cta = ({ data }) => (
  <section className={cx(`bg-${data.color}`, "py-20 md:py-28")}>
    <div
      className="container text-2xl md:text-5xl font-semibold leading-tight"
      data-aos="fade-up"
    >
      <div>
        {data.copy}
        &nbsp;
        <a
          href={data.ctaLink}
          className="underline inline font-semibold cursor-pointer"
        >
          {data.cta}
        </a>
      </div>
    </div>
  </section>
);
