import React from "react";

import { Seo } from "../components/seo";
import { renderSections } from "../lib/renderSections";

const Page = ({ pageContext, location }) => {
  const {
    content: { sections },
    meta,
  } = pageContext;

  return (
    <>
      <Seo metaInfo={meta} location={location} />
      {renderSections(sections, location)}
    </>
  );
};

export default Page;
