import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import cx from "classnames";
import Arrow from "../icons/arrow.js";
import * as styles from "./../components/postCard.module.scss";
import { PageLink } from "./link.js";
import PortableText from "./portableText.js";

export const Card = ({ data }) => {
  const content = (
    <div className="flex flex-col max-w-3xl">
      {data.image && (
        <div>
          <SanityImage
            className="w-full h-full object-cover"
            alt={data.title ? data.title : "card"}
            {...data.image}
            width={714}
            height={402}
          />
        </div>
      )}
      <div className={cx(styles.copy, "col-span-8 flex-1 pt-5 md:pt-6 pb-8")}>
        <div className="max-w-lg">
          {data.title && (
            <div className="text-lg md:text-xl font-semibold leading-snug">
              {data.title}
            </div>
          )}
          {data.copy && (
            <p className="font-serif md:my-5 my-8 leading-snug text-sm md:text-base first:mt-0">
              {data.copy}
            </p>
          )}
          {data.cta && (
            <PortableText
              className="font-sans md:my-5 my-8 leading-snug text-4xs first:mt-1.5"
              blocks={data.cta}
            />
          )}
        </div>
        <div className="flex flex-row justify-between">
          {data.subtitle && (
            <div>
              <div className="md:text-3xs text-4xs uppercase">
                {data.subtitle}
              </div>
            </div>
          )}
          {data.link && data.link[0] && (
            <div>
              <Arrow className={cx(styles.arrow)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (data.link && data.link[0]) {
    return <PageLink {...data.link[0]}>{content}</PageLink>;
  } else {
    return content;
  }
};
