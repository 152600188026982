const processNodes = (collection) => {
  collection = collection.nodes.map((obj) => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (key.startsWith("_raw")) {
          let newKey = key.slice(4);
          newKey = newKey.charAt(0).toLocaleLowerCase() + newKey.slice(1);
          obj[newKey] = obj[key];
          delete obj[key];
        }
      }
    }
    return obj;
  });

  return collection;
};
export default processNodes;
