import React from "react";
import YoutubeEmbed from "../components/youtubeEmbed";

export const Video = ({ data }) => (
  <section className="bg-white py-6 md:py-14">
    <div className="text-dark container" data-aos="fade-up">
      <div className="max-w-5xl flush-component">
        <YoutubeEmbed youtubeVideo={data.youtubeVideo} />
      </div>
      {data.copy && (
        <p className="mt-8 text-sm md:text-lg3 max-w-3xl font-serif">
          {data.copy}
        </p>
      )}
    </div>
  </section>
);
