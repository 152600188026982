import React from "react";
import PortableText from "../components/portableText";

export const CtaHero = ({ data }) => (
  <section className="text-dark mb-20 pt-20 mt-mnav md:mt-dnav">
    <div className="container text-center">
      <h1 className="text-2xl md:text-3xl">{data.title}</h1>
      <PortableText
        className="font-serif max-w-lg mx-auto text-base"
        blocks={data.cta}
      />
    </div>
  </section>
);
