import React from "react";
import PortableText from "../components/portableText";
import cx from "classnames";

export const LegalCopy = ({ data }) => (
  <section className={cx("text-black md:py-20 py-dnav md:mt-dnav")}>
    <div className="container">
      <PortableText
        className="font-serif max-w-3xl mx-auto text-base leading-normal"
        blocks={data.legalCopy}
      />
    </div>
  </section>
);
