import React from "react";
import { Card } from "../components/card.js";

export const CardSection = ({ data }) => (
  <section className="bg-white md:py-16 py-10">
    <div className="text-dark container" data-aos="fade-up">
      <Card data={data} />
    </div>
  </section>
);
