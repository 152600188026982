import React from "react";
import * as styles from "./homeApproach.module.scss";
import cx from "classnames";
import { PageLink } from "../components/link";

export const HomeApproach = ({ data }) => (
  <section className="bg-pink md:bg-white section overflow-hidden">
    <div className="text-dark" data-aos="fade-up">
      <div className="container">
        <h2 className="small-title bordered mb-5 md:mb-16">{data.title}</h2>
        <h3 className="mb-11 lg:mt-16 lg:mb-16 text-2xl lg:text-4xl font-semibold lg:mr-16">
          {data.copy}
        </h3>

        <div className="mb-10 md:mb-20 flex flex-row gap-8 md:grid grid-cols-2 xl:grid-cols-4 w-full overflow-y-auto -mx-6 px-6 lg:mx-0 lg:px-0 flush-component pb-5">
          {data.approaches.map((approach) => (
            <div
              key={approach._key}
              className={cx(
                styles.approach,
                "mr-0 last:mr-0 flex-auto bg-white lg:bg-pink p-10 w-72 xl:w-auto"
              )}
            >
              <h3 className="text-2xl border-dark border-b-2 mb-5 pb-3 whitespace-pre-wrap lg:whitespace-normal">
                {approach.title}
              </h3>
              <p className="font-serif text-sm mb-5">{approach.copy}</p>
            </div>
          ))}
        </div>

        {data.ctaLink && data.ctaLink[0] && (
          <PageLink
            className="button--outline min-width"
            link={data.ctaLink[0].link}
            _type={data.ctaLink[0]._type}
          >
            {data.ctaLink[0].title}
          </PageLink>
        )}
      </div>
    </div>
  </section>
);
