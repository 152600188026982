import React, { useEffect, useRef } from "react";
import * as styles from "./copyHero.module.scss";
import cx from "classnames";

export const CopyHero = ({ data }) => {
  const shape1 = useRef(null);
  const shape2 = useRef(null);
  const title1 = useRef(null);
  const title2 = useRef(null);

  const initShapeTransition = (element) => {
    const bounds = element.getBoundingClientRect();
    element.dataset.width = bounds.width;
    element.dataset.height = bounds.height;

    element.style.width = 0;
    element.style.height = 0;
  };

  const transitionShape = (element) => {
    setTimeout(() => {
      element.classList.add(styles.shapeTransition);
      element.style.width = element.dataset.width;
      element.style.height = element.dataset.height;
    }, 1);
  };

  const transitionTitle = (element) => {
    element.classList.add(styles.showTitle);
  };

  useEffect(() => {
    transitionTitle(title1.current);
  }, [title1]);

  useEffect(() => {
    transitionTitle(title2.current);
  }, [title2]);

  useEffect(() => {
    initShapeTransition(shape1.current);
    transitionShape(shape1.current);
  }, [shape1]);

  useEffect(() => {
    initShapeTransition(shape2.current);
    transitionShape(shape2.current);
  }, [shape2]);

  return (
    <section
      className={cx(
        "text-black md:pt-20 pt-dnav md:mt-dnav h-screen",
        `bg-${data.color}`,
        styles.section
      )}
    >
      <div className="container font-semibold flex flex-col lg:grid lg:grid-cols-12 md:gap-14 h-full">
        <h1 ref={title1} className={cx(styles.title, "mb-6 col-span-9")}>
          {data.title1}
        </h1>
        <div className="col-span-3 mb-6 hidden lg:block">
          <div className="h-full w-full flex flex-row justify-center items-center">
            <svg
              ref={shape1}
              className="stroke-current w-full h-full"
              viewBox="0 0 350 187"
              preserveAspectRatio="none"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M349.5 93.5C349.5 119.045 330.112 142.288 298.508 159.173C266.925 176.048 223.259 186.5 175 186.5C126.741 186.5 83.0749 176.048 51.4919 159.173C19.8877 142.288 0.5 119.045 0.5 93.5C0.5 67.9548 19.8877 44.7122 51.4919 27.8265C83.0749 10.9522 126.741 0.5 175 0.5C223.259 0.5 266.925 10.9522 298.508 27.8265C330.112 44.7122 349.5 67.9548 349.5 93.5Z" />
            </svg>
          </div>
        </div>
        <div className="col-span-4 mb-16 hidden lg:block">
          <div className="h-full w-full flex flex-row justify-center items-center">
            <svg
              ref={shape2}
              className="stroke-current w-full h-full"
              preserveAspectRatio="none"
              viewBox="0 0 470 259"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <rect x="0.5" y="0.5" width="469" height="258" />
            </svg>
          </div>
        </div>

        <div className="flex-1 lg:hidden w-1 mx-auto border-r border-black"></div>

        <div className="col-span-8 flex flex-row items-end">
          <h2
            ref={title2}
            className={cx(styles.title, "ml-auto mt-6 lg:mt-0 mb-16")}
          >
            {data.title2}
          </h2>
        </div>
      </div>
    </section>
  );
};
