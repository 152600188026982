import React, { useEffect, useState } from "react";
import cx from "classnames";
import IndustriesQuery from "../static-queries/industriesQuery";
import Toggle from "react-toggle";
import FoundersQuery from "../static-queries/foundersQuery";
import PortableText from "../components/portableText";
import queryString from "query-string";
import OpenClose from "../icons/openClose";
import Arrow from "../icons/arrow";
import * as styles from "./portfolioCompanies.module.scss";
import SanityImage from "gatsby-plugin-sanity-image";

export const PortfolioCompanies = (props) => {
  const founders = FoundersQuery();

  const [openedFounder, setOpenedFounder] = useState(
    queryString.parse(props.location.search)["founder_slug"]
  );
  const [selectedIndustryId, setSelectedIndustryId] = useState(null);
  const [exited, setExited] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [filteredFounders, setFilteredFounders] = useState(founders);

  useEffect(() => {
    if (
      typeof document !== "undefined" &&
      queryString.parse(props.location.search)["founder_slug"]
    ) {
      setTimeout(() => {
        openFounder(
          queryString.parse(props.location.search)["founder_slug"],
          true,
          0
        );
      }, 200);
    }
  }, [props.location.search]);

  useEffect(() => {
    const filteredFounders = founders
      .filter(
        (founder) =>
          founder.industries.find(
            (industry) => industry.industry.id === selectedIndustryId
          ) || !selectedIndustryId
      )
      .filter((founder) => exited === false || exited === founder.exited);

    setFilteredFounders(filteredFounders);
  }, [selectedIndustryId, exited]);

  let timeoutId = null;
  const filterFounders1 = (event) => {
    setTransitioning(true);
    setOpenedFounder(null);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setSelectedIndustryId(event.target.value);
      setTransitioning(false);
    }, 300);
  };

  const filterFounders2 = (event) => {
    setTransitioning(true);
    setOpenedFounder(null);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setExited(event.target.checked);
      setTransitioning(false);
    }, 300);
  };

  const openFounder = (slug, scroll, delay = 0) => {
    if (scroll || window.innerWidth < 768) {
      setOpenedFounder(null);
      setTimeout(() => {
        const element = document.getElementById(slug);

        if (element) {
          const headerOffset = 76;
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "auto",
          });
        }

        setOpenedFounder(slug);
      }, delay);
    } else {
      setOpenedFounder(slug);
    }
  };

  return (
    <section className="text-dark bg-white">
      <div className="bg-ghost py-7">
        <div className="container">
          <div className="flex flex-row items-center w-full">
            <div className="flex flex-col md:flex-row md:items-center flex-1 md:ml-7 md:mr-24">
              <div className="font-sans text-4xs sm:text-2xs font-semibold mb-4 md:mb-0 mr-3">
                FILTER BY SECTOR
              </div>
              <div className="flex-1 mr-3 sm:mr-5">
                <div className="input--select w-full">
                  <select
                    placeholder="All Sectors"
                    onChange={(event) => filterFounders1(event)}
                    onBlur={(event) => filterFounders1(event)}
                  >
                    <option value="">All Sectors</option>
                    {IndustriesQuery().map((industry) => (
                      <option key={industry.id} value={industry.id}>
                        {industry.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="font-sans text-4xs sm:text-2xs font-semibold mb-4 md:mb-0 md:mr-7">
                EXITED
              </div>
              <Toggle
                className={cx(
                  "input--toggle",
                  exited ? "opacity-100" : "opacity-40"
                )}
                defaultValue={false}
                icons={false}
                onChange={(event) => filterFounders2(event)}
                onBlur={(event) => filterFounders2(event)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div
          className={cx(
            "mt-11 pb-14 md:mt-16 md:pb-32 transition-opacity duration-500",
            transitioning ? "opacity-0" : "opacity-100"
          )}
          style={{ willChange: "opacity" }}
        >
          {filteredFounders.length === 0 ? (
            <i>No results</i>
          ) : (
            <table className="w-full">
              <thead>
                <tr>
                  {["COMPANY", "ABOUT", "", "FIRST INVESTED"].map(
                    (title, index) => (
                      <th
                        key={index}
                        className={cx(
                          "uppercase font-sans text-4xs lg:text-2xs font-normal text-left py-2 md:py-4 whitespace-nowrap",
                          index === 2 && "text-center",
                          index === 1 && "px-10",
                          index >= 2 && "hidden md:table-cell"
                        )}
                      >
                        {title}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredFounders.map((founder) => (
                  <React.Fragment key={founder.id}>
                    <tr
                      id={founder.slug.current}
                      className={cx(
                        "border-t border-black cursor-pointer last:border-b",
                        styles.row
                      )}
                      onClick={() =>
                        openedFounder === founder.slug.current
                          ? setOpenedFounder(null)
                          : openFounder(founder.slug.current, false, 50)
                      }
                    >
                      <td className="py-6 pl-0 sm:py-10 sm:pl-5 sm:align-top relative w-32 sm:w-2/12 align-middle">
                        <Arrow
                          className={cx(
                            "left-0 top-1/2 transform absolute fill-current text-dark hidden md:inline-block",
                            styles.arrow,
                            openedFounder === founder.slug.current
                              ? "-rotate-90 opacity-0"
                              : "rotate-90"
                          )}
                        />
                        {founder.logo && (
                          <SanityImage
                            className="max-w-full sm:ml-5 w-28 sm:w-44 grayscale filter transform"
                            alt={founder.name}
                            title={founder.name}
                            {...founder.logo}
                            height={54}
                          />
                        )}
                      </td>
                      <td className="font-serif italic text-xs sm:text-base p-2 py-6 pr-5 sm:py-10 sm:px-10 relative sm:w-6/12">
                        {founder.about}

                        <OpenClose
                          className={cx(
                            "right-0 top-1/2 transform -translate-y-3 absolute fill-current md:hidden transition-transform",
                            openedFounder === founder.slug.current &&
                              "rotate-180"
                          )}
                        />
                      </td>
                      <td className="text-2xs px-10 hidden md:table-cell w-2/12">
                        {founder.exited && (
                          <span className="text-white bg-black py-0.5 px-3 rounded uppercase text-2xs">
                            EXITED
                          </span>
                        )}
                      </td>
                      <td className="font-sans text-2xs py-5 text-center hidden md:table-cell w-1/12">
                        {founder.year}
                      </td>
                    </tr>
                    {(founder.image ||
                      founder.team ||
                      founder.whatWeDid ||
                      founder.social) && (
                      <>
                        <tr className="hidden md:table-row">
                          <td>
                            <div
                              className={cx(
                                styles.founder,
                                openedFounder === founder.slug.current &&
                                  styles.openedFounder
                              )}
                            >
                              <div className="py-8 align-top">
                                {founder.image && (
                                  <SanityImage
                                    className="max-w-full mb-8"
                                    alt={founder.name}
                                    {...founder.image}
                                    width={167}
                                    height={167}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="align-top">
                            <div
                              className={cx(
                                styles.founder,
                                openedFounder === founder.slug.current &&
                                  styles.openedFounder
                              )}
                            >
                              <div className="px-10 py-8 align-top grid grid-cols-2 gap-5">
                                {founder.team && (
                                  <div>
                                    <h4 className="text-xs uppercase mb-7">
                                      CEO & FOUNDERs
                                    </h4>
                                    <PortableText
                                      className="font-serif text-sm no-margin-after-p"
                                      blocks={founder.team}
                                    />
                                  </div>
                                )}

                                {founder.whatWeDid && (
                                  <div>
                                    <h4 className="text-xs uppercase mb-7">
                                      The big idea
                                    </h4>
                                    <PortableText
                                      className="font-serif text-sm no-margin-after-p"
                                      blocks={founder.whatWeDid}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td
                            className="align-top hidden md:table-cell"
                            colSpan="2"
                          >
                            <div
                              className={cx(
                                styles.founder,
                                openedFounder === founder.slug.current &&
                                  styles.openedFounder
                              )}
                            >
                              <div className="px-10 py-8 align-top">
                                {(founder.social || founder.link) && (
                                  <div className="rte">
                                    <h4 className="text-xs uppercase mb-7">
                                      Links
                                    </h4>
                                    {founder.link && (
                                      <a
                                        className="text-sm font-serif block"
                                        href={founder.link}
                                        onClick={(event) =>
                                          event.stopPropagation()
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Site
                                      </a>
                                    )}
                                    <PortableText
                                      className="font-serif text-sm no-margin-after-p"
                                      blocks={founder.social}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr className="md:hidden">
                          <td colSpan="2">
                            <div
                              className={cx(
                                styles.founder,
                                openedFounder === founder.slug.current &&
                                  styles.openedFounder
                              )}
                            >
                              <div>
                                {founder.image && (
                                  <SanityImage
                                    className="max-w-full mb-8"
                                    alt={founder.name}
                                    {...founder.image}
                                    height={167}
                                    width={167}
                                  />
                                )}

                                {founder.team && (
                                  <div className="mb-9 relative">
                                    <h4 className="text-4xs uppercase mb-1.5">
                                      CEO & FOUNDERs
                                    </h4>
                                    <PortableText
                                      className="font-serif text-2xs no-margin-after-p"
                                      blocks={founder.team}
                                    />
                                    {founder.exited && (
                                      <span className="text-white bg-black py-0.5 px-3 rounded uppercase text-2xs absolute top-0 right-0">
                                        EXITED
                                      </span>
                                    )}
                                  </div>
                                )}

                                {founder.whatWeDid && (
                                  <div className="mb-9 max-w-xs">
                                    <h4 className="text-4xs uppercase mb-1.5">
                                      THE BIG IDEA
                                    </h4>
                                    <PortableText
                                      className="font-serif text-2xs no-margin-after-p"
                                      blocks={founder.whatWeDid}
                                    />
                                  </div>
                                )}

                                {founder.social && (
                                  <div className="mb-9 rte">
                                    <h4 className="text-4xs uppercase mb-1.5">
                                      Links
                                    </h4>

                                    {founder.link && (
                                      <a
                                        className="text-2xs font-serif block"
                                        href={founder.link}
                                        onClick={(event) =>
                                          event.stopPropagation()
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Site
                                      </a>
                                    )}
                                    <PortableText
                                      className="font-serif text-2xs no-margin-after-p"
                                      blocks={founder.social}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};
